.modal-header {
  padding: 0.5rem 1rem;
}
.modal-footer {
  padding: 0.5rem 0.75rem;
}
.animate-modal {
  -webkit-animation-name: animateModal;
  animation-name: animateModal;
  -webkit-animation-duration: 0.25s; /* Safari 4.0 - 8.0 */
  animation-duration: 0.25s;
}
@keyframes animateModal {
  from {
    margin-top: -2rem;
  }
  to {
    margin-top: 1.75rem;
  }
}
@media only screen and (max-width: 540px) {
  @keyframes animateModal {
    from {
      margin-top: -2rem;
    }
    to {
      margin-top: 0.5rem;
    }
  }
}
