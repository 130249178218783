/*** Mobile Devices START ***/
@media only screen and (max-width: 540px) {
  .fc-button {
    padding: 0.2rem;
  }
  .fc-button .fc-icon {
    font-size: 1rem;
    line-height: 0.8rem;
  }
  .fc-toolbar h2 {
    font-size: 1rem;
  }
  .fc-button .fc-button-primary {
    font-size: 0.9rem;
  }
}
/*** Mobile Devices END ***/
