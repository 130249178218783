.help {
  display: inline;
}
.help-text {
  position: fixed;
  display: none;
  z-index: 1000;
  width: 200px;
  background: #000000cc;
  color: #efefef;
  font-size: 14px;
  padding: 2px 6px;
  border-radius: 3px;
  cursor: help;
  text-align: left;
}
.help:hover .help-text {
  display: block;
}
