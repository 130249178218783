.better-select {
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}
.better-select > select:hover {
  cursor: pointer;
}
.better-select::after {
  content: "";
  border-style: solid;
  border-color: currentColor;
  border-width: 0 0.12rem 0.12rem 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 9px;
  margin-bottom: 3px;
  margin-right: 3px;
}
.better-select > select {
  opacity: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
