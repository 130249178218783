.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}
.form-label-group > a,
.form-label-group > button,
.form-label-group > label {
  height: 3.125rem;
  padding: 0.75rem;
}
.form-label-group > label {
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}
.form-label-group button:not(:placeholder-shown),
.form-label-group a:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}
.form-label-group button:not(:placeholder-shown) ~ label,
.form-label-group a:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

/* Fallback for Edge */
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
}

/* Fallback for IE */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
}

.custom-dropdown > a::after {
  content: "";
  border-style: solid;
  border-color: currentColor;
  border-width: 0 0.12rem 0.12rem 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 8px;
  margin-bottom: 3px;
}
.custom-dropdown.dropup > a::after {
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  margin-bottom: 0;
}
.custom-dropdown.dropright > a::after {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-bottom: 2px;
}
.custom-dropdown.dropleft > a::after {
  content: none;
  border: none;
  margin-left: 0;
}
.custom-dropdown.dropleft > a::before {
  content: "";
  border-style: solid;
  border-color: currentColor;
  border-width: 0 0.12rem 0.12rem 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-right: 8px;
  margin-bottom: 2px;
}
.custom-dropdown > a.form-control::after {
  /* when floating label */
  float: right;
}

.animate-dropdown {
  -webkit-animation-name: animateDropdown;
  animation-name: animateDropdown;
  animation-duration: 0.1s;
  -webkit-animation-duration: 0.1s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}

@keyframes animateDropdown {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}
