.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}
.form-label-group > input,
.form-label-group > select,
.form-label-group > textarea,
.form-label-group > label {
  height: 3.125rem;
  padding: 0.75rem;
}
.form-label-group > label {
  text-align: left;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}
.form-label-group input::-webkit-input-placeholder,
.form-label-group textarea::-webkit-input-placeholder {
  color: transparent;
}
.form-label-group input:-ms-input-placeholder,
.form-label-group textarea:-ms-input-placeholder {
  color: transparent;
}
.form-label-group input::-ms-input-placeholder,
.form-label-group textarea::-ms-input-placeholder {
  color: transparent;
}
.form-label-group input::-moz-placeholder,
.form-label-group textarea::-moz-placeholder {
  color: transparent;
}
.form-label-group input::placeholder,
.form-label-group textarea::placeholder {
  color: transparent;
}
.form-label-group input:not(:placeholder-shown),
.form-label-group textarea:not(:placeholder-shown),
.form-label-group select:not(:placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}
.form-label-group input:not(:placeholder-shown) ~ label,
.form-label-group textarea:not(:placeholder-shown) ~ label,
.form-label-group select:not(:placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #777;
}

/* Red required star */
.form-label-group > label > span {
  position: absolute;
  top: 3px;
  left: 5px;
  color: #e02624;
  margin-right: 3px;
}

/* with custom arrow symbol */
.form-label-group > select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg height='24' width='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path fill='dimgray' d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z'/></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 3px);
  background-position-y: 12px;
}

/* Fallback for Edge */
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input::-ms-input-placeholder,
  .form-label-group textarea::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder,
  .form-label-group textarea:-ms-input-placeholder {
    color: #777;
  }
}
