.calendar {
  outline: none;
  width: 280px;
  user-select: none;
  font-size: 0.85rem;
}
.calendar .header {
  display: flex;
}
.calendar .header select {
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  background-color: #ededed;
  border: none;
  text-align: center;
  text-align-last: center;
  line-height: 16px;
  padding: 6px 7px 7px 7px;
}
.calendar .header select:hover {
  background-color: #cbcbcb;
}
.calendar .header select > option {
  background-color: #ededed;
}
.calendar .header button {
  background-color: #ededed;
  border: none;
  padding: 7px;
  background-repeat: no-repeat;
  background-position-y: 5px;
  min-width: 20px;
  cursor: pointer;
  line-height: 1rem;
}
.calendar .header button:hover {
  background-color: #cbcbcb;
}
.calendar .header button:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}
.calendar .header button.prev {
  background-image: url("data:image/svg+xml;utf8,<svg height='20' width='20' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path fill='currentColor' d='M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z'/></svg>");
}
.calendar .header button.next {
  background-image: url("data:image/svg+xml;utf8,<svg height='20' width='20' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path fill='currentColor' d='M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z'/></svg>");
  background-position-x: 100%;
}
.calendar .header > .month,
.calendar .header > .year,
.calendar .header > .today {
  display: flex;
  float: left;
  margin-bottom: 5px;
}
.calendar .header > .year,
.calendar .header > .today {
  margin-left: 8px;
}
.calendar .header > .today,
.calendar .header > .today > button {
  width: 100%;
}
.calendar table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid #f1f1f1;
  background-color: #ededed;
  table-layout: fixed;
}
.calendar table th {
  background-color: #e0f4fa;
  border: none;
  padding: 0.3rem 0.5rem;
  color: #75959e;
  font-weight: normal;
}
.calendar table td {
  color: black;
  background-color: white;
  padding: 0.5rem 0.5rem;
  border: 1px solid #f1f1f1;
}
.calendar table td:hover {
  cursor: pointer;
  background-color: #afe0f0;
}
.calendar table td.muted {
  background-color: transparent;
  border: none;
  cursor: default;
}
.calendar table td.disabled {
  color: grey;
  background-color: transparent;
  cursor: not-allowed;
}
.calendar table td.today {
  border: 2px solid turquoise;
}
.calendar table td.selected {
  background-color: #c9edf8;
}
