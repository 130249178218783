@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
.website {
  body {
    font-family: Roboto !important;
  }

  header {
    min-height: 55px;
  }
  main {
    min-height: calc(100vh - 55px - 20px - 190px);
    padding-bottom: 20px;
  }
  footer {
    min-height: 190px;
  }

  .navlink {
    color: black;
    font-weight: 500;
    font-size: 1.05rem;
    border-bottom: 0.13rem solid white;
    opacity: 0.72;
  }
  .navlink:hover {
    text-decoration: none;
    color: black;
    border-color: #17a2b8;
    opacity: 0.85;
  }
  .navlink.active {
    border-color: #17a2b8;
    opacity: 1 !important;
  }

  .random-pic {
    background: rgba(128, 128, 128, 0.56);
    font-size: 1rem;
    color: rgb(162, 162, 162);
    text-shadow: 0 0 1px black;
  }
  .random-pic small {
    text-shadow: none;
    font-size: 0.7rem;
  }
  .random-pic:hover {
    background: black;
    color: white !important;
    cursor: pointer;

    small {
      color: white !important;
    }
  }

  .jumbotron h1 {
    text-shadow: #128a9d 5px 5px;
    font-size: 4.5rem;
  }

  /*Medium devices (tablets, 768px and up)*/
  @media only screen and (max-width: 768px) {
    .navbar-brand {
      margin-right: 0 !important;
    }
    .jumbotron h1 {
      font-size: 2.5rem;
    }
  }

  /* Material Design Icon */
  svg.mdi {
    margin-top: -0.2rem;
  }

  /***UTILITIES START***/
  .no-hover-underline:hover {
    text-decoration: none !important;
  }

  .font-weight-500 {
    font-weight: 500 !important;
  }
  /***UTILITIES END***/
}
