.multi-select {
  position: relative;
}
.multi-select button {
  overflow: hidden;
  padding-right: 20px;
  position: relative;
  text-align: left;
}
.multi-select button::after {
  content: "";
  border-style: solid;
  border-color: currentColor;
  border-width: 0 0.12rem 0.12rem 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: absolute;
  top: 1.1rem;
  right: 11px;
}
.multi-select .count {
  padding: 0 6px;
  border-radius: 15px;
  font-size: 14px;
  margin-right: 5px;
  color: white;
  background: #6c757d;
}
.multi-select > .options {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0px;
  z-index: 1000;
  padding: 0.5rem 0;
  margin: 0.125rem 0px 0px;
  font-size: 1rem;
  color: rgb(33, 37, 41);
  list-style: none;
  background-color: rgb(255, 255, 255);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  user-select: none;
}
.multi-select > .options > .option {
  padding: 0.2rem 1rem 0.2rem 2.35rem;
  margin: 0;
}
.multi-select > .options > .option:hover {
  background-color: #f8f9fa;
}
.multi-select .option label {
  width: 100%;
}
.multi-select .more {
  border-top: 1px solid #d0d0d0;
  text-align: right;
  margin-top: 9px;
  min-height: 8px;
  position: relative;
}
.multi-select .more span {
  position: absolute;
  color: #afafaf;
  background: white;
  top: -13px;
  right: 0;
  padding: 0 10px;
  font-size: 14px;
}
.multi-select .more span:hover {
  cursor: pointer;
  color: gray;
}
.multi-select .nothing {
  font-size: 14px;
  text-align: center;
  color: gray;
}

/* Red required star */
.multi-select > .form-label-group > label > span {
  position: absolute;
  top: 3px;
  left: 5px;
  color: #e02624;
  margin-right: 3px;
}
