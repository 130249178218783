button:focus,
a:focus {
  outline: none!important;
}

/*** File Input ***/
.file-input {
  height: 100px;
  min-height: 100px;
  min-width: 100px;
  max-height: 500px;
  position: relative;
  overflow: hidden;
  background: #f1f9ff;
  border-radius: 0.25rem;
  margin-bottom: 15px;
  font-size: 12px;
  color: #b7daeb;
  opacity: 1;
}
.file-input :hover {
  cursor: pointer;
}
.file-input.disabled {
  opacity: 0.7;
}
.file-input.disabled :hover {
  cursor: default;
}
.file-input ul {
  margin: 0;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  text-shadow: 0 0 1px black;
  background: #d04b4a;
}
.file-input label {
  width: 100%;
  height: 100%;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  border: 0.07rem dashed #b7daeb;
  height: 100%;
  border-radius: 0.25rem;
}
.file-input label svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  fill: #b7daeb;
}
.file-input label p {
  position: absolute;
  bottom: 0.5rem;
  width: 100%;
  text-align: center;
  margin: 0;
  font-size: 0.9rem;
}
.file-input label input {
  opacity: 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}
.file-input label.hide {
  opacity: 0;
}
.file-input label.hide:hover {
  opacity: 0.4;
  text-shadow: 0 0 5px black;
}
.file-input .darken {
  box-shadow: inset 0 0 50px #39393994;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}
.file-input .inner {
  height: 100%;
}
.file-input .inner .preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #d0e3f1;
  height: 100%;
}
.file-input .inner .preview img {
  height: 100%;
}
.file-input .inner .preview .type {
  height: 100%;
  background: rgba(87, 125, 159, 0.5);
  padding-right: 1rem;
  padding-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #cbe3f7;
  font-size: 1.5rem;
}
.file-input .inner .details {
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
}
.file-input .inner .details span {
  margin: 8px;
  color: white;
  text-shadow: 0 0 1px black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.file-input .inner .details span.name {
  width: calc(100% - 50px);
  float: left;
}
.file-input .inner .details span.size {
  width: 50px;
  float: right;
  text-align: right;
}
.file-input .actions {
  position: absolute;
  width: 100%;
  bottom: 30px;
}
.file-input .actions button,
.file-input .actions a {
  position: absolute;
  z-index: 100;
  color: #f1f9ff;
  background: #0000004f;
  opacity: 0.4;
  cursor: pointer;
  border: none;
}
.file-input .actions button:hover,
.file-input .actions a:hover {
  opacity: 1;
}
.file-input .actions button.left,
.file-input .actions a.left {
  left: 0;
  text-align: left;
  border-top-right-radius: 75%;
  padding: 7px 12px 10px 8px;
}
.file-input .actions button.right,
.file-input .actions a.right {
  right: 0;
  text-align: right;
  border-top-left-radius: 75%;
  padding: 7px 8px 10px 12px;
}
.file-input .actions button svg,
.file-input .actions a svg {
  width: 24px;
  height: 24px;
}

/*** Zoom Modal ***/
.zoom-modal {
  width: 100%;
  height: 100%;
  background: #0000008f;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}
.zoom-modal .img-area {
  height: 70%;
  width: 70%;
  position: absolute;
  top: 15%;
  left: 15%;
  background: #000000c4;
  border-radius: 0.25rem;
  overflow: hidden;
  text-align: center;
  align-content: center;
}
.zoom-modal .img-area img {
  height: 100%;
  width: auto;
  max-height: 100%;
  max-width: 100%;
}
.zoom-modal .img-area .details {
  font-size: 1rem;
  position: absolute;
  z-index: 2;
  left: 8px;
  color: white;
  text-shadow: 0 0 2px black;
}
.zoom-modal .img-area .details.size {
  top: 2px;
}
.zoom-modal .img-area .details.name {
  bottom: 2px;
}
.zoom-modal .img-area button{
  position: absolute;
  z-index: 2;
  border: none;
  padding: 0;
  border-left: .12rem solid white!important;
  height: 2rem;
  width: 2rem;
  background: #191919;
  cursor: pointer;
  opacity: 0.6;
}
.zoom-modal .img-area button:hover{
  opacity: 1;
}
.zoom-modal .img-area button.leave{
  right: 0;
  top: 0;
  border-bottom: .12rem solid white!important;
  border-bottom-left-radius: 0.25rem;
}
.zoom-modal .img-area button.prev {
  right: 30px;
  bottom: 0;
  border-top: .12rem solid white!important;
  border-top-left-radius: 0.25rem;
}
.zoom-modal .img-area button.next {
  right: 0;
  bottom: 0;
  border-top: .12rem solid white!important;
}

/***  Mobile Devices  ***/
@media only screen and (max-width: 540px) {
  .file-input .actions span {
    opacity: 0.8;
  }
  .file-input label.hide {
    opacity: 0.3;
  }

  .zoom-modal .img-area {
    width: 96%;
    left: 2%;
    display: flex;
    align-items: flex-start;
  }
  .zoom-modal .img-area img {
    height: auto;
    width: 100%;
    align-self: center;
  }
  .zoom-modal .img-area button{
    opacity: 1;
  }
}


/*** Animations ***/
.animate {
  animation-duration: 0.15s;
  -webkit-animation-duration: 0.15s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
}
.fade-in {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
.zoom-in {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}