@import "../../../assets/css/colors.scss";
.app {
  header {
    min-height: 3.5rem;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  header .navbar {
    background: linear-gradient(120deg, $turquoise, #5983e8);
  }
  header .navbar .nav-link {
    font-size: 16px;
    color: #ffffffde !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  header .navbar .nav-link:hover {
    color: white !important;
    background: rgba(255, 255, 255, 0.12);
  }
  header .navbar .nav-link.active {
    background: rgba(255, 255, 255, 0.22);
  }

  .navbar-brand {
    margin-right: 0;
  }

  main {
    margin-top: 3.5rem;
    min-height: calc(100vh - 3.5rem - 3rem - 5px);
    padding: 1.5rem;
  }

  footer {
    min-height: 3rem;
    border-top: 2px solid #ddecf9;
    background-color: #b4d0e052;
    color: #9bc1ca !important;
  }
  footer a {
    color: #9bc1ca !important;
  }
  footer a:hover {
    color: #6ca2af !important;
    text-decoration: none;
  }
  footer a.disabled,
  footer a.disabled:hover {
    color: #aab6b9 !important;
    cursor: text;
  }

  .nav.nav-tabs .nav-item {
    margin-right: 0.5rem;
  }
  .nav.nav-tabs .nav-item:last-child {
    margin-right: 0;
  }
  .nav.nav-tabs .nav-link.active {
    background-color: $light-color;
    border-bottom-color: $light-color;
    box-shadow: inset 0px 3px $turquoise;
  }
  .profile-content {
    right: 0;
    left: auto !important;
  }

  /*** Mobile Devices START ***/
  @media only screen and (max-width: 540px) {
    header {
      position: relative;
    }
    main {
      min-height: calc(100vh - 3.5rem - 4rem);
      margin-top: 0;
      padding-right: 1rem;
      padding-left: 1rem;
    }
  }
  /*** Mobile Devices END ***/
}
