@import "./colors.scss";

body {
  // font-family: "Poppins", sans-serif;
  font-family: "Segoe UI";
}

// Remove rounded corners from every element
// * {
//   border-radius: 0 !important;
// }

// Material Design Icon
svg.mdi {
  margin-top: -0.2rem;
}

/*** Utilities START ***/
.no-hover-underline:hover {
  text-decoration: none !important;
}
.opacity-0 {
  opacity: 0;
}
/*** Utilities END ***/

/*** Mobile Devices START ***/
@media only screen and (max-width: 540px) {
  .btn-xs-block {
    width: 100%;
  }
  // do not let texts fall into next line in rows of table in mobile
  table.no-wrap {
    white-space: nowrap;
  }
}
/*** Mobile Devices END ***/

/*** Medium Devices START ***/
@media only screen and (max-width: 768px) {
  .btn-sm-block {
    width: 100%;
  }
}
/*** Medium Devices END ***/
