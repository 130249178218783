@import "../../assets/css/colors.scss";

.checkbox-card.disabled:hover,
.checkbox-card.disabled > label:hover {
  cursor: not-allowed;
}
.checkbox-card > label {
  width: 100%;
  height: 65px;
  display: block;
  background: white;
  overflow: hidden;
  cursor: pointer;
  margin: 0;
}
.checkbox-card > label input {
  display: none;
}
.checkbox-card > label input[type="checkbox"]:checked + .left {
  background: $turquoise;
  opacity: 1;
}
.checkbox-card > label .left {
  height: 100%;
  width: 65px;
  float: left;
  opacity: 0.5;
}
.checkbox-card > label .right {
  width: calc(100% - 65px - 5px);
  height: 100%;
  float: left;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.checkbox-card > label .right span {
  padding: 5px 10px;
  -webkit-align-self: center;
  align-self: center;
}
.checkbox-card.sm > label {
  height: 42px;
  font-size: 90%;
}
.checkbox-card.sm > label .left {
  width: 42px;
}
.checkbox-card.sm > label .right {
  width: calc(100% - 42px - 5px);
}
.checkbox-card.sm > div button {
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 85%;
}
